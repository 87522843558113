<template>
    <div class="content">
		<div class="header">
			<router-link class="logo" to="/">Mint</router-link>
			<div class="menu">
				<router-link to="/bets" v-if="isLoggedIn">Мои ставки</router-link>
				<a href="https://t.me/mintcalculator" target="_blank">Связаться</a>
				<a class="green" href="https://chrome.google.com/webstore/detail/mint/chgkcckddlnijbolpgpdocnhlajnfhdb" target="_blank">Установить расширение</a>
				<router-link to="/logout" @click="logout" v-if="isLoggedIn">Выйти</router-link>
			</div>
		</div>
	</div>
</template>

<script>

import {mapGetters, mapActions} from "vuex";

export default {
    computed: mapGetters(['isLoggedIn']),
	methods: mapActions(['logout'])
}
</script>