<template>
	<div>
		<Panel :auth="false"/>
		<div class="content">
			<Post v-for="(post, i) in posts" :key="i" 
				:title="post.title"
				:text="post.text"
			/>
		</div>
	</div>
	
</template>

<script>
// @ is an alias to /src
import Panel from "@/components/Panel.vue";
import Post from "@/components/Post.vue"
import HelloWorld from '@/components/HelloWorld.vue'

export default {
	data(){
		return {
			posts: [
				{title: "Mint больше не поддерживает работу со сканером!", text: "Все деньги за приобретенные подписки с 12 ноября 2022г до 24 ноября 2022г будут возвращены."},
				{title: "Что такое Mint?", text: `Mint - это расширение для вашего браузера, которое упрощает вашу работу как со сканером, так и без. 
		<br>Расширение добавляет на вкладки вашего браузера калькулятор, которым удобно пользоваться.
		<p><img src="/img/face.jpg"></p>
		<p>В функционал расширения, помимо калькулятора на странице, входит:
			<br><br>- Синхронизация между несколькими браузерами.
			<br>- Сохранение ваших вилок в статистику, которую вы можете посмотреть на сайте.
			<br>- Автоматическое добавление выбранной в сканере вилки в калькулятор: все исходы, БК и необходимые суммы у вас перед глазами.
			<br>- Автообновление выбранных страниц через случайные промежутки времени.
		</p>
		<br>
		<p>Работа расширения тестировалась на браузерах с движком "Chrome", вы можете установить его на все браузеры, работающие на этом движке (Yandex, Opera, Indigo "Minic" и т.д.)</p>
		<p>Некоторые функции расширения доступны только по подписке.</p>
		<br><br>Полную инструкцию вы можете прочитать по ссылке <a href="https://vk.com/@mintcalculator-instrukciya-kalkulyator-vilok-mint" target="_blank">в нашей группе ВКонтакте</a>`},
				{title: "Для чего нужна подписка?", text: `Бесплатная версия расширения предусматривает использование ручной версии калькулятора. Сами вносим значения, сами меняем. Только сухие цифры.
		<p>Подписка позволяет использовать расширение в паре со <a href="https://gg-cyber.space/">сканером вилок</a>. При выборе события в сканере оно автоматически дублируется в калькулятор, что заметно повышает скорость проставки. Так же подписка позволяет сохранять ваши ставки одним кликом, если вы работаете без сканера. Подписка на сайте включает в себя так же подписку на бота телеграм <a target="_blank"href="https://t.me/ostscorebot">@ostscorebot</a>, который позволяет следить за изменениями в счете игры по профилю игрока</p>
		<p>Используйте промокод "MINT" при оплате сканера и получите скидку 5%</p>
		<p>Цена подписки - 150р в неделю, 500р в месяц.<br>Чтобы приобрести подписку необходимо войти на сайт через Telegram</p>
		<p>После успешной оплаты подписка начисляется мнгновенно. Если у вас не закончилась подписка, при покупке новой - время действия складывается</p>`}
			]
		}
	},
  name: 'Home',
  components: {
    HelloWorld,
	Panel,
	Post
  },
  metaInfo(){
		return {
			title: "Mint - калькулятор вилок, который всегда перед глазами"
		}
	},
}
</script>
