<template>
    <div class="post">
        <h1 class="post_title">{{title}}</h1>
        <div class="post_text" v-html="text"> </div>
    </div>
</template>

<script>
export default {
    props: ['title', "text"]
}
</script>