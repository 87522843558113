import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    title: 'Mint - калькулятор вилок, который всегда перед глазами',
    name: "home",
    component: Home
  },
  {
    path: "/bets",
    name: "bets",
    title: "Ваши ставки. Mint",
    component: () => import('../views/Bets.vue'),
    meta: {
      requiresAuth:true
    }
  },
  {
    path: "/logout"
  },
  {
    path: "/404",
    name: "404",
    title: "Mint - калькулятор вилок, который всегда перед глазами",
    component: () => import('../views/404.vue')
  },
  {
    path: "*", redirect: "/404"
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
