import Vue from 'vue'
import VueMeta from "vue-meta";
import App from './App.vue'
import router from './router'
import store from './store'
//import axios from "axios";

//localStorage.setItem("token", "jdXPhaE6aFFUtGF")

// const http = axios.create();
// http.interceptors.response.use(async response=>{
// 	if (!response.data.success && response.data?.code == 401){
// 		console.log("error", response.data.error)
// 		await store.dispatch("login", {user: "kek", token: `dasfsdf${Math.random()}`})
// 		router.push("/").catch(error=>{
// 			console.log(error)
// 		})
// 		//await store.dispatch("logout")
// 	}
// 	return response
// }, async error=>{
// 	if (error?.response?.status === 401){
// 	}
// 	return Promise.reject(error)
// })
// http.interceptors.request.use(async request=>{
// 	if (store.state.token) request.headers.common["Authorization"] = store.state.token
// 	console.log(request)
// 	return request
// })

// Vue.prototype.$http = http

//const store = store_f(http)

Vue.config.productionTip = false
Vue.use(VueMeta);

Vue.directive('scroll', {
	inserted: function (el, binding) {
		let f = function (evt) {
			if (binding.value(evt, el)) {
				window.removeEventListener('scroll', f)
			}
		}
		window.addEventListener('scroll', f)
	}
})

router.beforeEach((to, from, next)=>{
	if (to.path == "/logout"){
		store.dispatch("logout");
		next("/")
		return;
	}
	if (to.meta.requiresAuth && !store.getters.isLoggedIn) next("/")
	else next()
})

new Vue({
	name: "Mint - калькулятор вилок, который всегда перед глазами",
  router,
  store,
  render: h => h(App)
}).$mount('#app')
