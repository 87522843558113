<template>
  <div id="app">
    <Header/>
    <Loader v-if="loading"/>
    <router-view v-if="!loading"/>
	<Footer v-if="!loading"/>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Loader from "@/components/Loader.vue";
import { mapActions, mapGetters } from "vuex";

export default {
    data(){
        return {
            loading: true,
            hash: get_hash(10)
        }
    },
	components: {
		Header,
		Footer,
        Loader
	},
    computed: mapGetters(['token']),
    methods:  mapActions(['initUser']),
	async mounted(){
        await this.initUser();
        this.loading = false
	},
    watch: {
        token(){
            window.dispatchEvent(new CustomEvent("update-token", {
                bubbles: true
            }));
        }
    }
}

function get_hash(length = 5){
    let result = ''
    const string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    while(result.length < length){
        const index = Math.floor(Math.random()*string.length)
        result = result+string[index]
    }
    return result;
}
</script>

<style>
	@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
body{
    font-family: 'Open Sans', sans-serif;
}
a{
    text-decoration: none;
}

.content{
    width: 1080px;
    margin: 0 auto;
}

.login{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.logo_b{
    margin-bottom: 25px;
}

.logo_b img{
    width: 234px;
}

.header{
    display: flex;
    justify-content: space-between;
    height: 60px;
    line-height: 60px;
}
.logo{
    color: #53aa53;
    font-size: 40px;
}
.menu{
    display: inline-block;
}
.menu a{
    color: #141414;
    padding-left: 20px;
}

.panel{
    background: #53aa53;
    color: #fff;
    text-align: center;
    padding: 50px 0;
}
.name{
    font-size: 23px;
    font-weight: 600;
    padding: 0 150px 15px;
    display: inline-block;
    border-bottom: 1px solid #fff;
}
.sub{
    padding:25px;
}
.buy_btn{
    display: inline-block;
    padding: 20px;
    background: #f3f3f3;
    color: #171717;
    border-radius: 5px;
	margin: 5px
}
.post{
    margin: 50px 0;
}
.post_title{
    font-size: 40px;
    margin: 20px 0;
}
.post_text p{
    margin:10px 0;
}
.red{
    color:rgb(211, 6, 6)
}
.green{
    color: #53aa53 !important;
}
.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#bets-list{
    list-style-type: none;
    display: inline-block;
	min-width: 625px;
}

.bets{
    margin: 20px 0;
}

.bets table{
    border-spacing: 0;
    font-size: 14px;
    padding-bottom: 10px;
    background: #fff;
    -webkit-box-shadow: 0px 4px 8px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 4px 8px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 4px 8px 0px rgba(34, 60, 80, 0.2);
}

.bets-header{
    background: #53aa53;
    height: 40px;
    color:#fff;
    text-align: center;
}

.bets button{
    border: none;
    cursor: pointer;
    color: #fff;
    outline: 0;
    outline-offset: 0;
    background: none;
}

.bets-save{
    text-align: right;
}
.bets-remove{
    text-align: right;
    padding: 0 15px;
}

.bets-collnames{
    font-size: 12px;
    text-align: center;
}
.bets-title{
    max-width: 285px;
}

.bet{
    text-align: center;
}
.bet td{
    padding: 10px;
}

.bet input[type=number], .bet input[type=text]{
    text-align: center;
    background: none;
    color: #000;
    border: 0;
    border-bottom: 1px solid #18b123;
    padding: 5px;
}

.bet input[type=number][readonly], .bet input[type=text][readonly]{
    border-bottom: 1px solid #03a9f4;
}

.bet input[type=number]:focus, .bet input[type=text]:focus{
    outline: 0;
    outline-offset: 0;
}

.bet input[type=number]::-webkit-outer-spin-button,
.bet input[type=number]::-webkit-inner-spin-button,
.bet input[type=text]::-webkit-outer-spin-button,
.bet input[type=text]::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.bet-price input, .bet-summ input{
    width: 75px;
}

.bet-bk input{
    width: 100px;
}
.bet-market input{
    width: 170px;
}
.bet-profit{
    width: 80px;
}

.bets-info{
    display: inline-block;
    vertical-align: top;
    margin: 20px 30px;
    background: #fff;
    -webkit-box-shadow: 0px 4px 8px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 4px 8px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 4px 8px 0px rgba(34, 60, 80, 0.2);
}

.bets-info ul{
    list-style: none;
    padding: 30px 60px;
}
.bets-info-head{
    background: #53aa53;
    color: #fff;
    height: 40px;
    line-height: 40px;
    text-align: center;
}

.bets-info input{
    margin: 5px 20px;
    font-family: 'Open Sans', sans-serif;
    border: 1px #d8d8d8 solid;
    border-radius: 2px;
    color: #2f2f2f;
    background-color: #fbfbfb;
    outline: none;
}

.bets-info .filter{
    text-align: center;
    padding: 15px 20px;
}

.bets-info button{
    border: none;
    cursor: pointer;
    color: #fff;
    outline: 0;
    outline-offset: 0;
    background: #53aa53;
    padding: 7px 20px;
}
.bets-stat{
    margin: 15px auto;
    font-size: 15px;
}
.bets-stat td{
    padding: 0 5px;
}
.no-bets{
    height: 240px;
    text-align: center;
    padding: 120px 0;
    color: #b5b5b5;
}
.footer{
	color: #9c9c9c;
    font-size: 13px;
    margin: 20px 0;
}
</style>
