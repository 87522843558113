<template>
    <div class="panel">
        <div class="name" v-if="isLoggedIn">{{name}}</div>
        <div class="sub" v-if="isLoggedIn">Подписка: {{subscription}}</div>
        <a v-if="isLoggedIn" href="#" class="buy_btn" @click="get_bill(7)">Продлить на неделю</a><a href="#" v-if="isLoggedIn" class="buy_btn" @click="get_bill(30)">Продлить на месяц</a>

        <div class="logo_b" v-if="!isLoggedIn">
			<img src="img/mint_w.png" alt="Mint">
		</div>
        <div id="tlogin" v-show="!isLoggedIn"></div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    mounted(){
        const script = document.createElement('script')
        script.async = true;
        script.src = "https://telegram.org/js/telegram-widget.js?15"
        script.setAttribute('data-telegram-login',"MintCalculatorBot")
        script.setAttribute('data-size',"large")
        window.onTelegramAuth = this.login;
        script.setAttribute("data-onauth", "window.onTelegramAuth(user)");
        script.setAttribute('data-request-access',"write")
        document.getElementById("tlogin").append(script)
    },
    methods: {
        ...mapActions(['login', 'logout']),
        async get_bill(time){
            const options = {headers: {}}
			if (this.token) options.headers["Authorization"] =  this.token
            const resp = await fetch(`/api/bill?time=${time}`, options).then(res=> res.json())
            if (resp.success) window.open(resp.url, '_blank' )
            else if (resp.code == 401) this.logout();
        }
    },
    computed: {
        ...mapGetters(['isLoggedIn', 'user', "token"]),
        name(){
            if (!this.user) return "";
            if (this.user.hasOwnProperty("user_name")) return `@${this.user.user_name}`
            else return `id: ${this.user.id}`
        },
        subscription(){
            if (!this.user || !this.user.subscription.active) return "Не активна";
            else {
                const date = new Date(this.user.subscription.end);
                const obj = {
                    day: addZero(date.getDate()),
                    month: addZero(date.getMonth()+1),
                    hours: addZero(date.getHours()),
                    min: addZero(date.getMinutes()),
                    year: `${date.getFullYear()}`.slice(2)
                }
                return `до ${obj.day}.${obj.month}.${obj.year} ${obj.hours}:${obj.min}`
            }

            function addZero(num){
                if (num < 10) return '0'+num;
                else return num;
            }
        }
    }
}
</script>