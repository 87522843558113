import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const BASE_URL = "";

export default new Vuex.Store({
	state: {
		user: null,
		token: localStorage.getItem("ostminttoken") || "",
		status: "",
		bets: []
	},
	mutations: {
		auth_success(state, {user, token}){
			state.user = user;
			state.token = token;
			state.status = "success";
		},
		auth_request(state){
			state.status = "loading"
		},
		auth_error(state, error){
			state.status = error;
		},
		logout(state){
			state.token = "";
			state.user = null;
			state.status = ""
		},
		init_user(state, user){
			state.user = user
		},
		setBets(state, bets){
			state.bets = bets
		},
		betsEnd(state){
			state.bets_end = true;
		},
		updateBet(state, bet){
			const index = state.bets.findIndex(val=> val._id == bet._id);
			if (index == -1) return;
			const markets = state.bets[index].markets;
			for (let id in markets){
				markets[id] = bet.markets[id];
			}
		},
		deleteBet(state, bet){
			const index = state.bets.findIndex(val=> val._id == bet._id);
			state.bets.splice(index, 1);
		}
	},
	actions: {
		logout({commit}){
			console.log("logoute")
			commit("logout");
			localStorage.removeItem('ostminttoken')
		},
		async login({commit}, user){
			const options = {
				method: "POST",
				body: JSON.stringify(user)
			}
			const resp = await fetch(`${BASE_URL}/api/tlogin`, options).then(resp=> resp.json());
			if (resp.success){
				commit("auth_success", resp.data)
				localStorage.setItem("ostminttoken", resp.data.token)
			}else commit("auth_error", resp.error)
		},
		async initUser({commit, dispatch, state}){
			const options = {headers: {}}
			if (state.token) options.headers["Authorization"] =  state.token
			const resp = await fetch(`${BASE_URL}/api/user`, options).then(res=> res.json());
			if (resp.success) commit("init_user", resp.user);
			else if(resp.code == 401) dispatch("logout")
		},
		async updateBet({commit, dispatch, state}, bet){
			for (let id in bet.markets){
				for (let key in bet.markets[id]){
					let val = bet.markets[id][key];
					if ((key == "price" || key == "bet") && val == "") bet.markets[id][key] = 0
				}
			}

			commit("updateBet", bet)
			const options = {
				method: "PUT",
				body: JSON.stringify(bet),
				headers: {}
			}
			if (state.token) options.headers["Authorization"] =  state.token
			const resp = await fetch(`${BASE_URL}/api/bets`, options).then(resp=> resp.json());
			if(!resp.success && resp.code == 401) dispatch("logout")
		},
		async deleteBet({commit, dispatch, state}, bet){
			commit("deleteBet", bet)
			const options = {
				method: "delete",
				body: JSON.stringify({id: bet._id}),
				headers: {}
			}
			if (state.token) options.headers["Authorization"] =  state.token
			const resp = await fetch(`${BASE_URL}/api/bets`, options).then(resp=> resp.json());
			if(!resp.success && resp.code == 401) dispatch("logout")
		},
		async loadBets({commit, dispatch, state}, date){
			const options = {headers: {}}
			if (state.token) options.headers["Authorization"] =  state.token
			let query = `?offset=${-new Date().getTimezoneOffset()}&date_start=${date.start}`;
			if (date.end) query += `&date_end=${date.end}`
			const resp = await fetch(`${BASE_URL}/api/bets${query}`, options).then(res=> res.json());
			if (resp.success) commit("setBets", resp.bets)
			else if(resp.code == 401) dispatch("logout")
		}
	},
	getters:{
		isLoggedIn: state => !!state.token,
		token: state => state.token,
		user: state => state.user,
		bets: state => state.bets,
		stats: state=>{
			let g_profit = 0;
			let profit_perc = 0;
			state.bets.forEach(bet=>{
				let { success:success_1, bet:bet_1, price:price_1 }  = bet.markets[1];
				let { success:success_2, bet:bet_2, price:price_2 }  = bet.markets[2];


				price_1 = price_1 ? parseFloat(price_1): 0
				price_2 = price_2 ? parseFloat(price_2): 0

				bet_1 = bet_1 ? parseFloat(bet_1): 0
				bet_2 = bet_2 ? parseFloat(bet_2): 0

				const profit_1 = success_1 && success_2 ? (price_1*bet_1)-bet_1 : !success_1 && !success_2 ? -bet_1 : (price_1*bet_1)-(bet_1+bet_2)
				const profit_2 = success_2 && success_1 ? (price_2*bet_2)-bet_2 : !success_2 && !success_1 ? -bet_2 : (price_2*bet_2)-(bet_2+bet_1)

				const profit = success_1 == success_2 ? profit_1+profit_2 : success_1 ? profit_1 : profit_2;
				const perc = profit / ((bet_1+bet_2)/100)

				g_profit += profit;
				profit_perc += perc
			})
			if (state.bets.length) profit_perc = profit_perc/state.bets.length
			return {profit: g_profit, percent: profit_perc}
		}
	}
})
